import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import {useSelector} from "react-redux";
import {isAuthenticated} from "../../store/slices/authSlice";

const CheckExtensionInstalled = () => {
    const [isExtensionLoaded, setIsExtensionLoaded] = useState(null);
    const [showInstallPopup, setShowInstallPopup] = useState(false);
    const isAuthenticatedStatus = useSelector(isAuthenticated);

    useEffect(() => {
        if( !isAuthenticatedStatus)
            return

        const timerId = setTimeout(() => {
            if (window.isTdtExtensionContentScriptLoaded) {
                setIsExtensionLoaded(true);
            } else {
                setIsExtensionLoaded(false);
                setShowInstallPopup(true);
            }
        }, 5000); // 5-second cooldown

        // Cleanup the timer on component unmount
        return () => clearTimeout(timerId);
    }, [isAuthenticatedStatus]);

    return (
        <>
            {isExtensionLoaded !== null && (
                <span
                    style={{
                        backgroundColor: isExtensionLoaded ? 'green' : 'red',
                        color: 'white',
                        padding: '0px 16px',
                        borderRadius: '4px',
                        display: 'inline-block',
                    }}
                >
                    {isExtensionLoaded ? 'TDT Dashboard Activity Monitor Detected' : 'TDT Dashboard Activity Monitor Not Detected'}
                </span>
            )}

            <Dialog open={showInstallPopup} onClose={() => setShowInstallPopup(false)}>
                <DialogTitle>TDT Dashboard Activity Monitor Not Detected</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ marginBottom: 2 }}>
                        The TDT Dashboard Activity Monitor extension is essential for automatically gathering the data needed to manage your Independent Contractors, compliance, vehicles, assets, and shifts through this dashboard and its related mobile applications.
                    </DialogContentText>
                    <DialogContentText sx={{ marginBottom: 2 }}>
                        Please install the TDT Dashboard Activity Monitor extension from the Chrome Web Store to enable full functionality. Click the button below to install it.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        href="https://chromewebstore.google.com/detail/tdt-dashboard-activity-mo/pnahgghnndjlilmdajmaigpaegbcfjbl"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Install Extension
                    </Button>
                    <Button onClick={() => setShowInstallPopup(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CheckExtensionInstalled;
