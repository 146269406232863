import React from 'react';
import {useEffect, useState} from 'react';
import {Paper, Typography, Box, Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import Base from '../base/Base';
import {setShowHeader} from "../../store/slices/navSlice";
import css from './Login.module.css';
import * as Yup from 'yup';
import FormComponent from "../../components/formik/FormComponent";
import {useDispatch} from "react-redux";
import {Link, Navigate, useLocation} from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Reset = (props) => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const loginMode = location.state?.loginMode
    const fromLogin = location.state?.fromLogin === true;

    useEffect(() => {
        dispatch(setShowHeader(false));
        return () => {
            dispatch(setShowHeader(true));
        }
    }, [dispatch]);

    if (!fromLogin) {
        // Redirect the user back to the '/login' page
        return <Navigate to="/login" replace />;
    }

    const initialValues = loginMode === 'mobile' ? {
        mobile: localStorage.getItem('dam_admin_mobile') ? localStorage.getItem('dam_admin_mobile') : ''
    } : {
        email: localStorage.getItem('dam_admin_email') ? localStorage.getItem('dam_admin_email') : ''
    };


    const formFields = loginMode === 'mobile' ? [
        {
            label: 'Mobile Number',
            name: 'mobile',
            variant: 'outlined',
            fullWidth: true,
            gridFullWidth: true,
            type: 'tel',
            inputProps: {inputMode: 'numeric'},
        }
    ] : [
        {
            label: 'Email',
            name: 'email',
            variant: 'outlined',
            fullWidth: true,
            gridFullWidth: true,
            type: 'email',
        }
    ];

    const validationSchema = loginMode === 'mobile' ? Yup.object().shape({
        mobile: Yup.string()
            .required('Mobile number is required')
            .matches(/^0\d{9,14}$/, 'Mobile number is not valid')
    }) : Yup.object().shape({
        email: Yup.string()
            .required('Email is required')
            .email('Email is not valid')
    });

    const onReset = (response, values) => {
        setError('');

        const extra = loginMode === 'mobile' ? {mobile: values.mobile, token: response.data.token} : {email: values.email, token: response.data.token};

        navigate('/confirmreset', { state: { extra: extra, fromReset: true } })
    }

    //console.log(initialValues, validationSchema, formFields)

    return (
        <Base>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={6} lg={4}>
                    <Paper component={Box} p={4} textAlign="center" className={css.paper}>
                        <Typography variant="h6" gutterBottom>
                            Password Reset
                        </Typography>
                        <FormComponent
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onReset}
                            formFields={formFields}
                            submitLabel="Submit"
                            url={`/api/auth/password/forgot`}
                            verb="post"
                            additionalData={{country: "27"}}
                        />
                        <Box mt={3}>
                            <Button
                                onClick={() => navigate(-1)}
                                variant="contained"
                                color="secondary"
                                type="button"
                                fullWidth
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Base>
    );

}

export default Reset;