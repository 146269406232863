import React, {startTransition, useCallback, useEffect, useState} from 'react';
import {
    AppBar, Badge,
    Box,
    Container,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    Dashboard as DashboardIcon,
    Engineering as EngineeringIcon,
    Logout as LogoutIcon,
    Menu as MenuIcon,
    Schedule as ScheduleIcon,
    Settings as SettingsIcon,
    Upload as UploadIcon,
    AccountBalance as BankFeedsIcon,
    Checklist as ChecklistIcon,
} from '@mui/icons-material';
import {styled} from '@mui/system';
import {useDispatch, useSelector} from "react-redux";
import {isAuthenticated, loggedInUser, selectRoles} from "../../store/slices/authSlice";
import {logoutUser} from "../../store/slices/authThunks";
import {useLocation, useNavigate} from "react-router-dom";
import {version} from "../../config/version";
import {TermsDialog} from "../../components/termsdialog/TermsDialog";
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupSelector from "../../components/selectors/GroupSelector";
import axios from "../../store/tokenAxios/tokenAxios";
import {fetchDataThunk} from "../../store/slices/fetchDataThunk";
import {getEchoInstance, pusherConnectedSignal} from "../../services/pusherEchoInstance";
import ConnectionIndicator from "../../services/ConnectionIndicator";
import {setSelectedBranch} from "../../store/slices/navSlice";
import BranchSelector from "../../components/selectors/BranchSelector";
import useSoundPlayer from "../../hooks/useSoundPlayer";
import Presence from "../dashboard/driver_dashboard/Presence";
import {signal} from "@preact/signals-react";
import CheckExtensionInstalled from "../../components/helpers/ExtensionHelper";
import {enqueueSnackbar} from "../../components/helpers/SnackbarHelper";
import ErrorBoundary from "../../hoc/ErrorBoundary";

export const accessControlEventSignal = signal(Date.now());

const MainContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignContent: 'center',
    textAlign: 'center',
    minHeight: '100vh',
    padding: '0px',
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8)
}));

const MenuContainer = styled(Box)(({theme}) => ({
    width: 200,
}));

const MenuItem = (props) => {
    const location = useLocation();
    const isCurrentRoute = location.pathname === props.path;

    return (
        <>
            <ListItem
                sx={{
                    backgroundColor: isCurrentRoute ? "rgba(0,0,0,0.2)" : "rgba(255,255,255,0.1)",
                    '&:hover': {
                        backgroundColor: "rgba(0,0,0,0.1)"
                    },
                    borderRadius: "5px",
                    margin: "2px"
                }}
                onClick={props.onClick}
                cursor={"pointer"}
            >
                <ListItemIcon sx={{minWidth: "30px", marginRight: '0px', color: "white"}}>
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={props.caption}
                              primaryTypographyProps={{
                                  style: {
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                  }
                              }}
                              sx={{
                                  transform: `scale(${isCurrentRoute ? "1" : "0.8"})`,
                                  transition: "transform 0.2s ease",
                                  paddingLeft: '0px',
                                  margin: '0px'
                              }}/>
            </ListItem>
            <Divider sx={{height: "1px", backgroundColor: "black"}}/>
        </>
    )
}

const Header = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userRoles = useSelector(selectRoles);
    const selectedBranch = useSelector((state) => state.nav.selectedBranch);
    const dashRoles = useSelector((state) => state.auth.dashRoles);

    const onGroupChanged = useCallback((branches) => {
        if (branches.length === 0) return;
        dispatch(setSelectedBranch(branches[0]));
    }, [dispatch]);

    //const fullName = loggedInUser ? `${loggedInUser.name} ${loggedInUser.surname}` : 'No user logged in';
    const onSetSelectedBranch = useCallback((branch) => {
        dispatch(setSelectedBranch(branch));
    }, [dispatch]);

    const fullName = loggedInUser.value;

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    const visibleTabs = () => {
        const doLogout = () => {
            startTransition(() => {
                dispatch(logoutUser("Manual logout", axios));
                navigate('/login');
            });
        }

        const tabData = [
            {
                caption: 'Operations',
                icon: <Badge
                    color="secondary"
                    badgeContent={0}
                ><EngineeringIcon/></Badge>,
                requiredRoles: [],
                requiredBranchRoles: ['operations'],
                path: '/operations',
            },
            {
                caption: 'Dashboards',
                icon: <DashboardIcon/>,
                requiredRoles: [],
                path: '/dashboard',
            },
            {
                caption: 'Payments',
                icon: <BankFeedsIcon/>,
                requiredRoles: [],
                excludedRoles: ['staff'],
                requiredBranchRoles: ['accounting'],
                path: '/payments',
            },
            {
                caption: 'Shifts',
                icon: <ScheduleIcon/>,
                requiredRoles: [],
                excludedRoles: ['staff'],
                requiredBranchRoles: ['shifting'],
                path: '/shifts',
            },
            {
                caption: 'Documents',
                icon: <UploadIcon/>,
                requiredRoles: [],
                path: '/documents',
            },
            {
                caption: 'Reports',
                icon: <AssessmentIcon/>,
                requiredRoles: [],
                path: '/reports',
            },
            {
                caption: 'Audit',
                icon: <ChecklistIcon/>,
                excludedRoles: [],
                requiredRoles: [],
                requiredBranchRoles: ['auditing'],
                path: '/audit',
            },
            {
                caption: 'Setup',
                icon: <SettingsIcon/>,
                excludedRoles: ['staff'],
                requiredRoles: [],
                path: '/setup',
            },
            {
                caption: `Logout ${fullName}`,
                icon: <LogoutIcon/>,
                requiredRoles: [],
                path: '/logout',
                action: doLogout
            },
        ];

        const visibleTabs = tabData.filter((tab) => {
            //console.log('tab', tab, userRoles, dashRoles);

            const excluded = tab.excludedRoles ? tab.excludedRoles.some((role) => userRoles.includes(role)) : false;
            const included = tab.requiredRoles.every((role) => userRoles.includes(role));
            const roleIncluded = (dashRoles && tab.requiredBranchRoles) ? tab.requiredBranchRoles.some((role) => dashRoles.includes(role)) : true;

            return roleIncluded && (!excluded && included);
        });


        return visibleTabs.map((item, index) => {
                const onAction = item.action ? item.action : () => {
                    startTransition(() => {
                        navigate(item.path)
                    });
                };

                return <MenuItem key={index} caption={item.caption} icon={item.icon} path={item.path} onClick={onAction}
                                 cursor={"pointer"}/>
            }
        );
    }

    const drawer = (
        <Drawer
            variant="temporary"
            anchor="left"
            open={isDrawerOpen}
            onClose={toggleDrawer}
            onMouseLeave={closeDrawer}
            ModalProps={{keepMounted: true}}
        >
            <MenuContainer>
                <List>
                    {visibleTabs()}
                </List>
            </MenuContainer>
        </Drawer>
    );

    const horizontalMenu = (
        <Box sx={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
            {visibleTabs()}
        </Box>
    );

    return (
        <AppBar position="fixed" sx={{maxHeight: '65px'}}>
            <Toolbar>
                {!matches && (
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        cursor={"pointer"}
                        onClick={toggleDrawer}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                )}
                <ErrorBoundary area={"AppBar"}>
                    <GroupSelector onGroupChanged={onGroupChanged}></GroupSelector>
                    <BranchSelector mainOnly={true} selectedBranch={selectedBranch}
                                    onBranchSelected={onSetSelectedBranch}
                                    styles={{
                                        backgroundColor: "white",
                                        minWidth: "200px",
                                    }}/>
                </ErrorBoundary>
                {matches ? horizontalMenu : drawer}
                <ConnectionIndicator isConnected={pusherConnectedSignal}/>
                {selectedBranch?.code &&
                    <Presence branchCode={selectedBranch.code}/>
                }
            </Toolbar>
        </AppBar>
    )
}

const Footer = () => {

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                width: '100%',
                height: '20px',
                backgroundColor: 'primary.main',
                textAlign: 'center',
                color: 'black',
                fontSize: '0.7em',
                zIndex: 10
            }}
        >
            version {version} - <TermsDialog>Terms and Conditions of use</TermsDialog> - <CheckExtensionInstalled/>
        </Box>
    )
}

export function authHeader() {
    const token = localStorage.getItem('dam_admin_token');
    if (token) {
        return `Bearer ${JSON.parse(token).token}`;
    }
    return null;
}

const BaseLayout = (props) => {
    const selectedStateBranch = useSelector((state) => state.nav.selectedBranch);
    const isAuthenticatedStatus = useSelector(isAuthenticated);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {playEntrySound, playMessageSound, playErrorSound, playPopSound, playLittleBellSound} = useSoundPlayer();

    window.echoInstance = null;

    useEffect(() => {
        const dontNavigate = ['/login', '/reset-password', '/set-password'];
        if (!isAuthenticatedStatus) {
            if (!dontNavigate.includes(window.location.pathname)) {
                navigate('/');
            }
        }
    }, [isAuthenticatedStatus, navigate]);

    useEffect(() => {
        if (!selectedStateBranch?.code) return;
        if (!isAuthenticatedStatus) return;

        const echo = getEchoInstance();
        const channel = `queue.${selectedStateBranch.code}.status`;

        console.log('channel', channel);

        echo.channel(channel)
            .listen('QueueStatusMessage', (e) => {
                if (e.queue_name === 'manual_queue') {
                    console.log('manual queue status', e);
                    //setRefreshTrigger(Date.now());
                    playLittleBellSound();

                }
            })
            .error((e) => {
                console.log('error', e);
            });

        return () => {
            echo.leave(channel);
        }
    }, [selectedStateBranch.code, isAuthenticatedStatus, playLittleBellSound]);

    useEffect(() => {
        if (!selectedStateBranch) return;
        if (!isAuthenticatedStatus) return;

        const echo = getEchoInstance();
        const notificationsChannel = `notifications.${selectedStateBranch.code}`;
        const accessChannel = `access_control.${selectedStateBranch.code}`;
        //const privateChannel = `branch_chat.${selectedStateBranch.code}`;
        //const chatChannel = `branch_chat.${selectedStateBranch.code}.general`;
        const presenceChannel = `presence.${selectedStateBranch.code}`;
        const shiftingChannel = `shifting.${selectedStateBranch.code}`;


        const notificationsRef = echo.channel(notificationsChannel)
            .listen('NotificationMessage', (e) => {
                console.log('NotificationMessage base', e);
                if (e.variant === 'warning' || e.variant === 'error') {
                    enqueueSnackbar(
                        e.message,
                        {
                            variant: e.variant,
                            autoHideDuration: 30000,
                            autoClose: true,
                        }
                    );
                    playErrorSound();
                } else {
                    enqueueSnackbar(e.message, {variant: e.variant});

                    playMessageSound();
                }
            })
            .error((e) => {
                console.log('error', e);
            });

        const accessRef = echo.channel(accessChannel)
            .listen('AccessControlMessage', (e) => {
                console.log('AccessControlMessage base', e);

                if (e.driver_id) {
                    accessControlEventSignal.value = Date.now();
                }

                playEntrySound();

                enqueueSnackbar(`${e.name} has entered the office`, {variant: 'info'})
            })
            .error((e) => {
                console.log('error', e);
            });

        console.log('AccessControlMessage base subscribe', accessRef);

        const shiftingChannelRef = echo.channel(shiftingChannel)
            .listen('ShiftingMessage', (e) => {
                playPopSound();

                enqueueSnackbar(`${e.message}`, {variant: 'info'})
            })
            .error((e) => {
                console.log('error', e);
            });

        /*echo.private(chatChannel)
            .listen('ChatMessage', (e) => {
                console.log('chat message', e);
                enqueueSnackbar(`${e.name} says: ${e.message}`, {variant: 'info'})
            })
            .error((e) => {
                console.log('error', e);
            });*/


        /*echo.join(presenceChannel)
            .here((users) => {
                console.log('here', users);
                // Triggered when a user joins the channel or on initial connection
                // `users` is an array of all users currently in the channel
            })
            .joining((user) => {
                console.log('joining', user);
                // Triggered when a new user joins the channel
            })
            .leaving((user) => {
                console.log('leaving', user);
                // Triggered when a user leaves the channel
            })
            .listen('ChatMessage', (e) => {
                console.log('chat message', e);
                enqueueSnackbar(`${e.name} says: ${e.message}`, {variant: 'info'})
            })
            .error((e) => {
                console.log('error', e);
            });*/


        return () => {
            console.log('AccessControlMessage base leave');
            echo.leave(notificationsChannel);
            echo.leave(accessChannel);
            //echo.leave(chatChannel);
            echo.leave(shiftingChannel);
            //echo.leave(presenceChannel);
        }
    }, [isAuthenticatedStatus, playEntrySound, playErrorSound, playMessageSound, playPopSound, selectedStateBranch]);


    useEffect(() => {
        dispatch(fetchDataThunk()).then(
            () => {
                console.log('data fetched')
            }
        );
    }, [dispatch, isAuthenticatedStatus]);

    console.log('BaseLayout render');

    return (
        <ErrorBoundary area={"BaseLayout"}>
            <CssBaseline/>
            {!!isAuthenticatedStatus &&
                <Header/>
            }
            <MainContainer>
                {props.children}
            </MainContainer>
            <Footer/>
        </ErrorBoundary>
    );
};

export default BaseLayout;
