import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Collapse,
    IconButton,
    Typography,
    Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null, showDetails: false };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });
        console.error(
            `Error caught by ErrorBoundary [Area: ${this.props.area || ""}]:`,
            error,
            errorInfo
        );
    }

    toggleDetails = () => {
        this.setState((prevState) => ({ showDetails: !prevState.showDetails }));
    };

    render() {
        if (this.state.hasError) {
            return (
                <Dialog open={true} fullWidth maxWidth="sm" aria-labelledby="error-dialog-title">
                    <DialogTitle id="error-dialog-title">Oops! Something Went Wrong</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            An unexpected error occurred. Please try refreshing the page or contact support if the
                            problem persists.
                        </DialogContentText>

                        {this.state.error && (
                            <Box mt={2}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onClick={this.toggleDetails}
                                    sx={{
                                        cursor: "pointer",
                                        bgcolor: "rgba(0, 0, 0, 0.04)",
                                        padding: 1,
                                        borderRadius: 1,
                                    }}
                                >
                                    <Typography variant="subtitle2" color="text.secondary">
                                        {this.state.showDetails
                                            ? "Hide Error Details"
                                            : "Show Error Details"}
                                    </Typography>
                                    <IconButton size="small">
                                        {this.state.showDetails ? (
                                            <ExpandLessIcon fontSize="small" />
                                        ) : (
                                            <ExpandMoreIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                </Box>
                                <Collapse in={this.state.showDetails}>
                                    <Box
                                        mt={2}
                                        sx={{
                                            bgcolor: "rgba(0, 0, 0, 0.02)",
                                            padding: 2,
                                            borderRadius: 1,
                                            fontSize: "0.9rem",
                                            whiteSpace: "pre-wrap",
                                            color: "text.secondary",
                                            maxHeight: 200,
                                            overflowY: "auto",
                                        }}
                                    >
                                        <Typography variant="subtitle2">
                                            <strong>Error:</strong> {this.state.error.toString()}
                                        </Typography>
                                        <Typography variant="caption">
                                            {this.state.errorInfo?.componentStack}
                                        </Typography>
                                    </Box>
                                </Collapse>
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.location.reload()}
                        >
                            Refresh Page
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
