import {Slide, toast} from "react-toastify";


export const enqueueSnackbar = (title, config) => {
    const func = config.variant === 'error' ? toast.error : (config.variant === 'warning' ? toast.warn : config.variant === 'success' ? toast.success : toast.info);

    const merged = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: Slide,
        ...config
    };

    func( title, merged);
}